<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent transition="scale-transition" max-width="1200px">
            <v-card class="elevation-1">
                <ValidationObserver ref="obsSucursalEdit" v-slot="{invalid, validated}" :immediate="true">
                    <v-form name="form" @submit.prevent="updateSucursal">
                        <v-toolbar dark color="primary">
                            <v-btn icon dark @click.native="handleModal">
                                <v-icon>fas fa-times-circle</v-icon>
                            </v-btn>
                            <v-toolbar-title>Editar sucursal</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn class="danger" text type="submit" :disabled="invalid || !validated">
                                    <v-icon class="ma-2" dense large left>fas fa-save</v-icon>
                                    Guardar Cambios
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }" :immediate="true">
                                        <v-autocomplete :items="empresas" item-text="empresa" item-value="id"
                                        label="Empresa*" v-model="sucursal.idEmpresa" required outlined
                                        :error-messages="errors" :success="valid"
                                        prepend-inner-icon="fas fa-briefcase">
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field label="Clave sucursal" v-model="sucursal.clave"
                                    prepend-inner-icon="fas fa-keyboard" outlined
                                    maxlength="10" counter readonly>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <ValidationProvider rules="required" v-slot="{ errors, valid }" :immediate="true">
                                        <v-text-field label="Nombre del sucursal*" v-model="sucursal.nombre"
                                        prepend-inner-icon="fas fa-lock" outlined
                                        required :error-messages="errors" :success="valid">
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" sm="6" md="8">
                                    <v-text-field label="Direccion" v-model="sucursal.direccion" hint="Direccion de la sucursal"
                                    prepend-inner-icon="fas fa-map-marker-alt" persistent-hint outlined>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-checkbox  class="mx-2" v-model="sucursal.activo" :hint="(sucursal.activo == false ? 'Habilitar ' : 'Desactivar ') +'sucursal en el sistema'"
                                    persistent-hint :label="sucursal.activo == true ? 'Sucursal Activa': 'Sucursal inactiva'"
                                    outlined
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-form>
                </ValidationObserver>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import SucursalDataService from '@/service/catalogo/sucursal/sucursalDataService';
export default {
    name: 'EditarSucursal',
    props: {
        dialog: { type: Boolean, default: false },
        sucursal: { type: Object },
        initialize: { type: Function },
        handleModal: { type: Function },
        empresas: { type: Array, default: () => [] }
    },
    methods: {
        updateSucursal(){
            this.$refs.obsSucursalEdit.validate().then( isValid => {
                if( isValid ){
                    SucursalDataService.update( this.sucursal).then( resp => {
                        if( resp.status == 200 ){
                            this.handleModal();
                            this.initialize();
                            this.$swal.fire({ position: 'top-end', icon: 'success', title: 'Actualización', text: 'Sucursal actualizada', showConfirmButton: false, timer: 4000 });
                        }
                    }).catch( error => {
                        this.$swal.fire({ position: 'top-end', icon: 'error', html: error.response.data,
                        title: 'Error', showConfirmButton: false, timer: 4000 });
                    })
                }
            }).catch( error => {
                this.$swal.fire({ position: 'top-end', icon: 'error', title: 'Error', html: error.message, showConfirmButton: false, timer: 4000 });
            })
        }
    },
}
</script>